import React, { useRef, useState } from "react";
import { EntityType, IExportExcelRequest, IFilterItem } from "../../../model/IExportExcelRequest";
import { Subset } from "../../../typings/GlobalExtensions";
import { Button, Card, CardActions, CardContent, CardHeader, createStyles, Grid, LinearProgress, MenuItem, TextField, Theme, WithStyles, withStyles } from "@material-ui/core";
import PersonalBibNumber from "./Views/PersonalBibNumber";
import { ExportViewRef } from "./Views/models";
import { ApiBackend } from "../../../providers/apibackend";

type ExportableTypes = Subset<EntityType, "PersonalBibNumber">;
type EmptyValueType = "EMPTY_VALUE";
const EMPTY_VALUE: EmptyValueType = "EMPTY_VALUE";

const ExportOrders = ({ classes }: WithStyles) => {
    const [exportType, setExportType] = useState(EMPTY_VALUE as ExportableTypes | EmptyValueType);
    const [filter, setFiter] = useState(null as IFilterItem[]);
    const [loading, setLoading] = useState(false);

    const ref = useRef<ExportViewRef>(null);
    const api = new ApiBackend();

    const getFilterView = () => {
        switch (exportType) {
            case "PersonalBibNumber":
                return <PersonalBibNumber ref={ref} onChange={setFiter} />
            default:
                return null;
        }
    };

    const exportExcel = async () => {
        const request: IExportExcelRequest = {
            entityType: exportType as ExportableTypes,
            filter: filter
        };
        try {
            setLoading(true);

            await api.exportExcel(request);
        } finally {
            setLoading(false);
        }
    };

    const isExportEnabled = () => {
        return filter && ref.current?.isValid();
    };

    const render = () => {
        return (<Card>

            <CardHeader className={classes.cardHeader} title="Exporter" />
            <CardContent>
                {loading &&
                    <LinearProgress color="secondary" />
                }
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            style={{ flex: 1, margin: '0 3px' }}
                            label="Exporttyp"
                            InputLabelProps={{ shrink: true }}
                            value={exportType ?? ""}
                            fullWidth
                            onChange={(e) => {
                                setExportType(e.target.value as ExportableTypes);
                            }}
                        >
                            {exportType === EMPTY_VALUE && <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>VÄLJ</MenuItem>}
                            <MenuItem key="PersonalBibNumber" value="PersonalBibNumber">Personlig nummerlapp</MenuItem>;
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        {getFilterView()}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary"
                    disabled={!isExportEnabled()}
                    onClick={exportExcel}
                >
                    Exportera
                </Button>
            </CardActions>
        </Card>
        );
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ExportOrders);
