import Axios from "axios";
import { ActiveProductCatalogue } from "../model/ActiveProductCatalogue";
import { B2bCompany, B2bCompanyCreate } from "../model/B2bCompany";
import { Campaign, CampaignType } from "../model/Campaign";
import { Club } from "../model/Club";
import { Code } from "../model/Code";
import { CodeHolder } from "../model/CodeHolder";
import { CronJob } from "../model/CronJob";
import { CustomField } from "../model/CustomField";
import { MismatchingOrderPaymentSum } from "../model/DashboardReports/MismatchingOrderPaymentSum";
import { DiplomaConfig } from "../model/DiplomaConfig";
import { CompanyEntry, Entry, IListEntriesResponse } from "../model/Entry";
import { EntryStatistics } from "../model/EntryStatistics";
import { Event, IEventWithProducts } from "../model/Event";
import { ExternalSeedingRace } from "../model/ExternalSeedingRace";
import { ExternalSeedingResult } from "../model/ExternalSeedingResult";
import { IExportExcelRequest } from "../model/IExportExcelRequest";
import { Message } from "../model/Message";
import { AddOnSummaryItem, DiplomaOrderItem, EnervitOrderItem, FolksamOrderItem, ListOrder, Order } from "../model/Order";
import { OrderContact } from "../model/OrderContact";
import { OrderItem } from "../model/OrderItem";
import { OrderStatistics } from "../model/OrderStatistics";
import { Orderoverview } from "../model/Orderoverview";
import { OrderPayment } from "../model/Payment";
import { IListPersonsResponse, Person } from "../model/Person";
import { PersonResult } from "../model/PersonResult";
import { BeloppsregleringName, KortterminalVlName, PointOfSale } from "../model/PointOfSale";
import { Postcode } from "../model/Postcode";
import { Product, ProductType } from "../model/Product";
import { IAddOrUpdateProductBundleResponse, IGetProductBundleResponse, IListProductBundlesResponse, ProductBundle } from "../model/ProductBundle";
import { ProductCatalogue } from "../model/ProductCatalogue";
import { ProductToDeliver } from "../model/ProductToDeliver";
import { Profile } from "../model/Profile";
import { RaceEntryOverview } from "../model/RaceEntryOverview";
import { ReportTrippel } from "../model/ReportTrippelParticipants";
import { Reservation } from "../model/Reservation";
import { SeedingTable } from "../model/SeedingTable";
import { IUpdateSupportTicketRequest, SupportTicket, SupportTicketStatus, SupportTicketType } from "../model/SupportTicket";
import { TransferToken } from "../model/TransferToken";
import Firebase from "./Firebase/firebase";
import { ListInvoicesParameters, SetInvoiceStatusRequest, ListCodeHoldersParameters, ListEntriesParameters, ListOrdersParameters, ListPersonsParameters, RebookOrderItemRequest, IValidateCodeResponse, ListB2bCompaniesParameters, BulkPatchEntriesRequest } from "./models";
import { IInvoice } from "../model/Invoice";
import { RaceType } from "../model/ProductVariantRaceData";
import { IResponseBase } from "../model/IResponseBase";
import { IAddOnOrderItem, IGetAddOnOrderItemsResponse } from "../model/IAddOnOrderItem";
import { AccountingAccountItem, IGetAccountingAccountsResponse } from "../model/IGetAccountingAccountsResponse";
import { IGetOrderTransactionRowsResponse, OrderTransactionRow } from "../model/OrderTransactionRow";

const buildUrl = (entryPath: string) => {
    return `${ApiBackend.config.backendBaseUrl}${entryPath}`;
};

const buildConfig = (token: string) => {
    return {
        headers: {
            Authorization: token
        }
    };
};

export class ApiBackend {
    static config: config = CONFIG;
    static firebase: Firebase;
    static arraySeparator = "::";

    static async initialize(firebase: Firebase) {
        ApiBackend.firebase = firebase;
    }

    static getCurrentUserName() {
        return ApiBackend.firebase.auth().currentUser.displayName;
    }

    static readonly productTypes: ReadonlyArray<{ name: string, code: ProductType }> = [
        { name: 'Ackreditering', code: 'acred' },
        { name: 'Annat', code: 'other' },
        { name: 'Bussbiljett', code: 'busticket' },
        { name: 'Diplom', code: 'diploma' },
        { name: 'Event', code: 'event' },
        { name: 'Lopp', code: 'race' },
        { name: 'Massage', code: 'massage' },
        { name: 'Motionsförsäkring', code: 'insurance' },
        { name: 'Presentkort', code: 'giftcard' },
        { name: 'Spårkort', code: 'skipass' },
        { name: 'Vallaservice', code: 'skiwax' }
    ];

    static readonly raceTypes: ReadonlyArray<{ name: string, code: RaceType }> = [
        { name: 'Individuell', code: 'individual' },
        { name: 'Stafett', code: 'relay' },
        { name: 'Team', code: 'team' }
    ];

    static readonly campaignTypes: ReadonlyArray<{ name: string, code: string }> = [
        { name: 'Vid köp av produkt', code: CampaignType.Code4Product }
    ];

    static getProductQrCodeUrl(productid: string): string {
        return ApiBackend.config.backendBaseUrl + "binary/products/" + productid + "/qrcode";
    }
    static getStartCertificateQrCodeUrl(entryId: string): string {
        return ApiBackend.config.backendBaseUrl + "binary/entries/" + entryId + "/startcertificateqrcode";
    }

    static getPdfReceiptUrl(orderid: string): string {
        return ApiBackend.config.backendBaseUrl + "binary/orders/" + orderid + "/pdf";
    }
    static getStartCertificatePdfUrl(entryid: string): string {
        return ApiBackend.config.backendBaseUrl + "binary/entries/" + entryid + "/startcertificatepdf";
    }

    static buildQueryString<T>(obj: T): string {
        const params = new URLSearchParams();

        Object.keys(obj).forEach((key) => {
            const value = obj[key as keyof T];
            if (Array.isArray(value)) {
                const val = value.join(ApiBackend.arraySeparator);
                if (val) {
                    params.append(key, val)
                }
            } else if (value !== null && value !== undefined && value !== "") {
                params.append(key, value.toString())
            }
        })

        return params.toString();
    }

    downloadAccountingXlsx(year: number, month: number, exportVerDate: string, exportRowComment: string, pointOfSale: string): Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "binary/accounting?year=" + year + "&month=" + month + "&verdate=" + exportVerDate + "&rowcomment=" + exportRowComment + "&pointofsale=" + pointOfSale,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        Authorization: token
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let y = year + "";
                    let m = month + "";
                    if (+month < 9) {
                        y = (+year - 1) + "";
                        m = (+month + 4) + "";
                    }
                    else {
                        m = (+month - 8) + "";
                    }
                    link.setAttribute('download', 'accounting_kontrollen_' + y + '_' + m + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
        });
    }

    downloadAccountingDailyReport(date: string): Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "binary/accountingdaily?date=" + date,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        Authorization: token
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'kassarapport_vl_' + date + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
        });
    }

    getProfile(): Promise<Profile> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "account", {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {
                let profile = new Profile(resp.data);
                return profile;
            });
        }
        ).catch((err) => {
            return null;
        });
    }

    updateProfileRoles(userId: string, roles: string[]): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "account/" + userId + "/roles", roles, {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {
                return resp.data;
            });
        }
        ).catch((err) => {
            return null;
        });
    }

    updateProfile(profile: Profile): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "account", profile,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    static listAllProfilesCancelToken = Axios.CancelToken.source();
    listAllProfiles(search: string): Promise<Profile[]> {
        if (ApiBackend.listAllProfilesCancelToken) {
            ApiBackend.listAllProfilesCancelToken.cancel();
        }

        ApiBackend.listAllProfilesCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const paramFilter = {
                s: search
            };
            const params = ApiBackend.buildQueryString<typeof paramFilter>(paramFilter);

            return Axios.get(ApiBackend.config.backendBaseUrl + "account/accounts?" + params,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    static listCodesCancelToken = Axios.CancelToken.source();
    listCodes(parameters: ListCodeHoldersParameters): Promise<CodeHolder[]> {
        if (ApiBackend.listCodesCancelToken) {
            ApiBackend.listCodesCancelToken.cancel();
        }

        ApiBackend.listCodesCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const params = ApiBackend.buildQueryString<ListCodeHoldersParameters>(parameters);
            const url = ApiBackend.config.backendBaseUrl + "codes?" + params;

            return Axios.get(url, {
                headers: {
                    Authorization: token
                },
                cancelToken: ApiBackend.listCodesCancelToken.token
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listCodeHolderCategories(): Promise<string[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "codes/categories",
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return [];
            });
        });
    }

    listCompanyCategories(): Promise<string[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "b2b/companies/categories",
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return [];
            });
        });
    }

    listClubs(includeArchived: boolean): Promise<Club[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/clubs" + (includeArchived ? "?includeArchived=true" : ""),
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    saveClub(club: Club): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/clubs/" + club.id,
                club,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getCode(codeHolderId: string): Promise<CodeHolder> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listEvents(): Promise<Event[]> {
        return Axios.get(ApiBackend.config.backendBaseUrl + "events",
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            return null;
        });
    }

    listDiplomas(): Promise<DiplomaConfig[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/diplomas",
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data.diplomaConfigs;
            }).catch((err) => {
                return null;
            });
        });
    }

    addDiplomaConfig(config: DiplomaConfig): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "products/diplomas", config,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    static listOrdersCancelToken = Axios.CancelToken.source();
    listOrders(parameters: ListOrdersParameters): Promise<ListOrder[]> {
        if (ApiBackend.listOrdersCancelToken != null) {
            ApiBackend.listOrdersCancelToken.cancel();
        }

        ApiBackend.listOrdersCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {

            const params = ApiBackend.buildQueryString<ListOrdersParameters>(parameters);

            return Axios.get(ApiBackend.config.backendBaseUrl + "orders?" + params,
                {
                    cancelToken: ApiBackend.listOrdersCancelToken.token,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    ApiBackend.listOrdersCancelToken = null;
                    return res.data;
                }).catch((err) => {
                    ApiBackend.listOrdersCancelToken = null;
                    return null;
                });
        });
    }

    static listInvoicesCancelToken = Axios.CancelToken.source();
    listInvoices(filter: ListInvoicesParameters): Promise<IInvoice[]> {
        if (ApiBackend.listInvoicesCancelToken != null) {
            ApiBackend.listInvoicesCancelToken.cancel();
        }

        ApiBackend.listInvoicesCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {

            const params = ApiBackend.buildQueryString<ListInvoicesParameters>(filter);

            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/invoices?" + params,
                {
                    cancelToken: ApiBackend.listInvoicesCancelToken.token,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    ApiBackend.listInvoicesCancelToken = null;
                    return res.data;
                });
        });
    }

    setInvoiceStatus(
        payments: { id: number; externalRef: string; }[],
        codeHolders: { id: string; externalRef: string; }[],
        b2bCustomProducts: { id: string; externalRef: string; }[],
        invoiceStatus: "CREATED"): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const data: SetInvoiceStatusRequest = {
                payments: payments,
                codeHolders: codeHolders,
                b2bCustomProducts: b2bCustomProducts,
                invoiceStatus: invoiceStatus,
                issuedBy: ApiBackend.getCurrentUserName(),
                issuedWhen: new Date()
            };

            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/invoices/invoicestatus", data,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data?.status === "OK";
                }).catch((err) => {
                    return false;
                });
        });
    }

    listDiplomaOrders(): Promise<DiplomaOrderItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/diplomaorders',
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listFolksamOrders(fromDate: string, toDate: string): Promise<FolksamOrderItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/folksamorders?fromDate=' + fromDate + "&toDate=" + toDate,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listTrippelParticipants(): Promise<ReportTrippel> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/trippelreport',
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listFolksamSummary(fromDate: string, toDate: string): Promise<AddOnSummaryItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/folksamsummary?fromDate=' + fromDate + "&toDate=" + toDate,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listTShirtSummary(raceId: string): Promise<AddOnSummaryItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/tshirtsummary?raceId=' + raceId,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listEnervitOrders(raceProductId: string, fromDate: string, toDate: string): Promise<EnervitOrderItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/enervitorders?raceProduct=' + raceProductId + "&fromDate=" + fromDate + "&toDate=" + toDate,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listEnervitSummary(fromDate: string, toDate: string): Promise<AddOnSummaryItem[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + 'orders/reports/enervitsummary?fromDate=' + fromDate + "&toDate=" + toDate,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.orders;
                }).catch((err) => {
                    return null;
                });
        });
    }

    addOrderPayment(payment: { orderId: string, paymentType: "PAYMENT" | "REVERSAL", amount: number, pointOfSaleId: string, description: string }): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "orders/" + payment.orderId + "/payments", payment,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    sendOrderPaymentRequest(orderId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderId + "/sendOrderPaymentLink", {},
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listPointOfSales(): Promise<PointOfSale[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/pos",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getBeloppsregleringPointOfSale(): Promise<PointOfSale> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get<PointOfSale[]>(ApiBackend.config.backendBaseUrl + "orders/pos",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.find(x => x.name === BeloppsregleringName);
                }).catch((err) => {
                    return null;
                });
        });
    }

    getKortterminalVlPointOfSale(): Promise<PointOfSale> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get<PointOfSale[]>(ApiBackend.config.backendBaseUrl + "orders/pos",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.find(x => x.name === KortterminalVlName);
                }).catch((err) => {
                    return null;
                });
        });
    }

    addOrUpdatePointOfSales(pos: PointOfSale): Promise<PointOfSale> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/pos", pos,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    deletePointOfSale(pos: PointOfSale): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "orders/pos/" + pos.id,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listProductsToDeliver(filter: string = null, productId: string, productVariantIds: string[], includeDelivered: boolean): Promise<ProductToDeliver[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const paramFilter = {
                productId: productId,
                productVariantIds: productVariantIds.join(','),
                s: filter,
                includeDelivered: (includeDelivered ? "true" : "false")
            };
            const params = ApiBackend.buildQueryString<typeof paramFilter>(paramFilter);

            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/deliver?" + params,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    deliverProduct(orderId: string, productId: string, productVariantId: string, productVariantPriceGroupId: string, numItems: number): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "orders/deliver", { orderId: orderId, productId: productId, productVariantId: productVariantId, productVariantPriceGroupId: productVariantPriceGroupId, numDelivered: numItems },
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    deliverOrderItem(orderId: string, orderItemId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "orders/deliver", { orderId: orderId, orderItemId: orderItemId },
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    createOrder(order: Order): Promise<Order> {
        return Axios.put(ApiBackend.config.backendBaseUrl + "checkout/order", order).then((res: { data: { order: Order } }) => {
            if (res && res.data) {
                return res.data.order;
            }
            else {
                return null;
            }
        }).catch((err) => {
            console.log(err);
            return null;
        });

    }

    adminCreateOrder(order: Order, suppressEmail?: boolean): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "checkout/admin/order", { ...order, ...{ suppressEmail: suppressEmail } },
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                if (res && res.data) {
                    return res.data.order;
                } else {
                    return null;
                }
            }).catch((err) => {
                console.log(err);
                return null;
            });
        });
    }

    createB2bInvoiceOrder(order: Order): Promise<Order> {
        return Axios.put(ApiBackend.config.backendBaseUrl + "b2b/invoiceorder", order).then((res: { data: { order: Order } }) => {
            if (res && res.data) {
                return res.data.order;
            } else {
                return null;
            }
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    retreiveOrderByPublicId(id: string): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/publicid/" + id,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    retreiveOrder(id: string): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/" + id,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data.order;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getOrderCommunicationHistory(orderid: string): Promise<Message[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/messages",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getOrderPaymentHistory(orderid: string): Promise<OrderPayment[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/payments",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    updateOrderContact(orderid: string, contact: OrderContact): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/contact", contact,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }


    addOrderItems(orderid: string, items: OrderItem[]): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/addOrderItems", { orderItems: items },
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    updateOrderItems(orderid: string, items: OrderItem[]): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/items", items,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    revertOrderItems(orderid: string, items: OrderItem[], revertedBy: string, revertedReason: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/revertItems", { orderItemIds: items.map(x => x.Id), revertedBy: revertedBy, revertedReason: revertedReason },
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    rebookOrderItems(orderId: string, request: RebookOrderItemRequest): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderId + "/rebookItems", request, {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getOrderOverview(productId: string, fromDate?: string, toDate?: string): Promise<Orderoverview> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const paramFilter = {
                fromDate: fromDate,
                toDate: toDate
            };
            const params = ApiBackend.buildQueryString<typeof paramFilter>(paramFilter);

            return Axios.get(ApiBackend.config.backendBaseUrl + `orders/overview/${productId}?${params}`, {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    static listEntriesCancelToken = Axios.CancelToken.source();
    listEntries(parameters: ListEntriesParameters): Promise<IListEntriesResponse | null> {

        if (ApiBackend.listEntriesCancelToken) {
            ApiBackend.listEntriesCancelToken.cancel();
        }

        ApiBackend.listEntriesCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const params = ApiBackend.buildQueryString<ListEntriesParameters>(parameters);

            return Axios.get(ApiBackend.config.backendBaseUrl + "entries?" + params, {
                headers: {
                    Authorization: token
                },
                cancelToken: ApiBackend.listEntriesCancelToken.token
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    downloadEntriesAsCSV(eventId: string): Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "binary/events/" + eventId + "/entries", {},
                {
                    responseType: 'arraybuffer',
                    headers: {
                        Authorization: token
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'entries_' + eventId + '_' + (new Date().toISOString()) + '.csv');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
        });

    }

    static listPersonsCancelToken = Axios.CancelToken.source();
    searchPersons(parameters: ListPersonsParameters): Promise<IListPersonsResponse | null> {
        if (ApiBackend.listPersonsCancelToken) {
            ApiBackend.listPersonsCancelToken.cancel();
        }

        ApiBackend.listPersonsCancelToken = Axios.CancelToken.source();
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const params = ApiBackend.buildQueryString<ListPersonsParameters>(parameters);

            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/persons?" + params,
                {
                    headers: {
                        Authorization: token
                    },
                    cancelToken: ApiBackend.listPersonsCancelToken.token
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getPerson(id: string): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/persons/" + id,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }


    getPersonResults(id: string): Promise<PersonResult[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/persons/" + id + "/results",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res: { data: { personId: string, results: PersonResult[] } }) => {
                    return res.data.results;
                }).catch((err) => {
                    return null;
                });
        });
    }

    savePerson(person: Person): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/persons/" + person.id, person,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    mergePerson(personId: string, personIdToMergeTo: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/persons/" + personId + "/merge/" + personIdToMergeTo, {},
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    addPerson(person: Person): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "entries/persons/", person,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listB2bCompanies(parameters: ListB2bCompaniesParameters): Promise<B2bCompany[] | null> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const params = ApiBackend.buildQueryString<ListB2bCompaniesParameters>(parameters);
            const url = ApiBackend.config.backendBaseUrl + "b2b/companies?" + params;

            return Axios.get(url,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listCompanyProductEntries(companyId: string, companyProductId: string): Promise<{ customHeaders: { key: string; name: string; }[]; entries: CompanyEntry[] }> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}b2b/companies/${companyId}/entries?companyProductId=${companyProductId}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getB2bCompany(id: string): Promise<B2bCompany> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "b2b/companies/" + id,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    if (res && res.data && !res.data.products) {
                        res.data.products = [];
                    }

                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    saveB2bCompany(company: B2bCompany): Promise<B2bCompany | string> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "b2b/companies/" + company.id, company,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    if (err.response.status === 400) {
                        return err.response.data;
                    }
                    return null;
                });
        });
    }

    createB2bCompany(values: B2bCompanyCreate): Promise<B2bCompany> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "b2b/companies/", values,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getEntryStatistics(): Promise<EntryStatistics> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "statistics/entries",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getOrderStatistics(): Promise<OrderStatistics> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "statistics/orders",
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getEvent(eventId: string): Promise<Event> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "events/" + eventId,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    createOrUpdateEvent(event: IEventWithProducts): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "events", event, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCode(codeHolderId: string, code: Code): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes", code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCode(codeHolderId: string, code: Code): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/" + code.id, code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCode(codeHolderId: string, code: Code): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/" + code.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteEvent(event: Event): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "events/" + event.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteDiploma(diploma: DiplomaConfig): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/diplomas/" + diploma.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createOrUpdateCode(code: CodeHolder): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/", code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    generateCodes(
        codeHolderId: string,
        numCodes: number,
        maxActivations: number,
        prefix: string,
        numBlocks: number,
        numChars: number): Promise<Code[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const data = {
                numCodes: numCodes,
                maxActivations: maxActivations,
                prefix: prefix,
                numBlocks: numBlocks,
                numChars: numChars
            };
            return Axios.post<Code[]>(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/generate", data, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return undefined;
            });
        });
    }

    deleteCodeHolder(code: CodeHolder): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "codes/" + code.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteProduct(product: Product): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/" + product.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getProduct(id: string): Promise<Product> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/" + id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getProductsForEvent(eventId: string): Promise<Product[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}events/${eventId}/products`, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateAgeClassForParticipants(productId: string, variantId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(`${ApiBackend.config.backendBaseUrl}products/${productId}/${variantId}/updateageclass`, null, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getEntryOverview(eventId: string): Promise<RaceEntryOverview> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/overview/" + eventId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getEntry(entryId: string): Promise<Entry> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/" + entryId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    saveEntry(entry: Entry): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/", entry,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    setEntryB2b(entryId: string, b2bId?: string): Promise<boolean> {
        const request = {
            entryId: entryId,
            b2bId: b2bId
        };
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/" + entryId + "/setEntryB2b", request,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data?.status === "OK";
                }).catch((err) => {
                    return false;
                });
        });
    }

    bulkPatchEntries(patchDocument: BulkPatchEntriesRequest): Promise<IResponseBase | false> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/patch", patchDocument,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                });
        });
    }

    splitTeamEntry(entryId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/" + entryId + "/splitteam", null,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data?.status === "OK";
                }).catch((err) => {
                    return false;
                });
        });
    }

    listProducts(includeArchived: boolean = false): Promise<Product[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/?showarchived=" + (includeArchived ? "1" : "0"), {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listSeedingTables(): Promise<SeedingTable[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/seeding/tables", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    runSeedingCalculationForRaceAndGroups(raceId: string, groups: string[], dryRun: boolean, notifyChanges: boolean): Promise<{ jobid: string }> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/seeding/run", {
                race: raceId,
                runForGroups: groups,
                dryRun: dryRun,
                notifyStartGroupChange: notifyChanges
            }, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }
    getSeedingCalculationForRaceAndGroupsStatus(jobid: string): Promise<{ id: string, status: string, progress: number, request: string, response: string }> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/seeding/run/status/" + jobid, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }


    listExternalSeedingRaces(year: number): Promise<ExternalSeedingRace[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces/" + year, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addOrUpdateExternalSeedingRace(race: ExternalSeedingRace): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces", race, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }
    deleteExternalSeedingRace(year: number, raceId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces/" + year + "/" + raceId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    prepareExternalSeedingResults(year: number, raceId: string, results: ExternalSeedingResult[]): Promise<ExternalSeedingResult[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces/" + year + "/" + raceId + "/prepare",
                { results: results }, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data.results;
            }).catch((err) => {
                return false;
            });
        });
    }


    getExternalSeedingResults(year: number, raceId: string): Promise<ExternalSeedingResult[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces/" + year + "/" + raceId + "/results", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data.results;
            }).catch((err) => {
                return false;
            });
        });
    }

    importExternalSeedingResults(year: number, raceId: string, results: ExternalSeedingResult[]): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/seeding/externalraces/" + year + "/" + raceId + "/import",
                { results: results }, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addOrUpdateSeedingTable(table: SeedingTable) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/seeding/tables", table, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteSeedingTable(tableId: string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/seeding/tables/" + tableId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listAvailableSeedingRaces(year: number): Promise<string[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/seeding/availableraces/" + year, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listCustomFields(): Promise<CustomField[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/customfields/", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCustomField(field: CustomField): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "products/customfields/", field, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCustomField(field: CustomField): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/customfields/" + field.id, field, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCustomField(fieldId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/customfields/" + fieldId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listReservations(includeArchived: boolean = false): Promise<Reservation[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/reservations", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createReservation(reservation: Reservation): Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "entries/reservations", reservation, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateReservation(reservation: Reservation): Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/reservations/" + reservation.id, reservation, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteReservation(reservation: Reservation): Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "entries/reservations/" + reservation.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createProduct(product: Product): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "products/", product, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateProduct(product: Product): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/" + product.id, product, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    resendOrderconfirmation(orderId: string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderId + "/sendconfirmation", null, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listCronJobs() {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "cronjobs", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "cronjobs", job, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "cronjobs/" + job.id, job, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "cronjobs/" + job.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    static getPostCodeCancelToken = Axios.CancelToken.source();
    getPostCode(postcode: string): Promise<Postcode> {
        if (ApiBackend.getPostCodeCancelToken) {
            ApiBackend.getPostCodeCancelToken.cancel();
        }

        ApiBackend.getPostCodeCancelToken = Axios.CancelToken.source();
        const url = `${ApiBackend.config.backendBaseUrl}externaldata/postcodes/${postcode}/SE`;
        return Axios.get(url, { cancelToken: ApiBackend.getPostCodeCancelToken.token })
            .then((res) => {
                ApiBackend.getPostCodeCancelToken = null;
                return res.data;
            }).catch(() => {
                ApiBackend.getPostCodeCancelToken = null;
                return null;
            });
    }

    getGeneral<T extends unknown>(path: string): Promise<(T | false)> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + path, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data as T;
            }).catch((err) => {
                return false;
            });
        });
    }

    postGeneral(path: string, data: any) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + path, data, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    putGeneral(path: string, data: any) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + path, data, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteGeneral(path: string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + path, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listCampaigns(): Promise<Campaign[] | null> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}campaigns`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getCampaign(id: string): Promise<Campaign> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}campaigns/${id}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    saveOrUpdateCampaign(campaign: Campaign): Promise<Campaign> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}campaigns`, campaign,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    addCampaignCodes(campaignId: string, codes: string[]) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}campaigns/${campaignId}/codes`, codes,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    getOrdersWithWrongPaymentSum(): Promise<MismatchingOrderPaymentSum[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}orders/reports/dashboard_orderswrongpaymentsum`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    getEntriesWithWrongClub(): Promise<Entry[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}orders/reports/entry_wrongclubreport`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    getEntriesForPerson(personId: string): Promise<Entry[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}entries/persons/${personId}/entries`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return [];
                });
        });
    }

    getOrdersForPerson(personId: string): Promise<ListOrder[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}entries/persons/${personId}/orders`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return [];
                });
        });
    }

    createTransferTokenForPerson(personId: string, method: string): Promise<TransferToken> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}entries/persons/${personId}/transfertoken/${method}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    createTransferTokenForCompayAdmin(email: string): Promise<TransferToken> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}entries/companies/transfertoken/${email}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    exportExcel(request: IExportExcelRequest, fileName?: string): Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const saveFileName = !!fileName ? fileName : request.entityType + "_" + (new Date().toISOString()) + '.xlsx';
            return Axios.post(ApiBackend.config.backendBaseUrl + "binary/excelexport",
                request,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        Authorization: token,
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', saveFileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
        });
    }

    listProductCatalogues(): Promise<ProductCatalogue[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}products/catalogues`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    if (res.data?.status === "OK") {
                        return res.data.catalogues;
                    }
                    return [];
                }).catch((err) => {
                    return [];
                });
        });
    }

    getProductCatalogue(catalogueId: string): Promise<ProductCatalogue> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}products/catalogues/${catalogueId}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    if (res.data?.status === "OK") {
                        return res.data.catalogue;
                    }
                    return null;
                }).catch((err) => {
                    return null;
                });
        });
    }

    addOrUpdateProductCatalogue(catalogue: ProductCatalogue): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}products/catalogues/${catalogue.id}`, catalogue, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data?.status === "OK";
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteProductCatalogue(catalogueId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(`${ApiBackend.config.backendBaseUrl}products/catalogues/${catalogueId}`, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data?.status === "OK";
            }).catch((err) => {
                return false;
            });
        });
    }

    listActiveCatalogues(): Promise<ActiveProductCatalogue[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}products/activecatalogues`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    if (res.data?.status === "OK") {
                        return res.data.catalogues;
                    }
                    return [];
                }).catch((err) => {
                    return [];
                });
        });
    }

    addOrUpdateActiveProductCatalogue(catalogue: ActiveProductCatalogue): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}products/activecatalogues/${catalogue.catalogueKey}`, catalogue, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data?.status === "OK";
            }).catch((err) => {
                return false;
            });
        });
    }

    async validateProductDiscountCode(code: string, productId: string, productVariantId: string): Promise<IValidateCodeResponse | null> {
        const body = {
            productId: productId,
            productVariantId: productVariantId
        };

        return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + code + "/validateforproduct", body).then((res) => {
            if (res && res.data) {
                return res.data;
            }
            else {
                return null;
            }
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    estimateOrder(order: Order): Promise<{ totalPrice: number, order: Order }> {
        return Axios.post(ApiBackend.config.backendBaseUrl + "checkout/order/estimate", order).then((res) => {
            if (res && res.data) {
                return res.data;
            }
            else {
                return null;
            }
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    adminEstimateOrder(order: Order): Promise<{ totalPrice: number, order: Order }> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "checkout/admin/order/estimate", order,
                {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                if (res && res.data) {
                    return res.data;
                } else {
                    return null;
                }
            }).catch((err) => {
                console.log(err);
                return null;
            });
        });
    }

    getSupportTicket(ticketId: string): Promise<SupportTicket> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}b2b/tickets/${ticketId}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    if (res.data?.status === "OK") {
                        return res.data.ticket;
                    }
                    return null;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listSupportTickets(typeFilter?: SupportTicketType[], statusFilter?: SupportTicketStatus[], companyId?: string): Promise<SupportTicket[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const paramFilter = {
                typeFilter: typeFilter,
                statusFilter: statusFilter,
                companyId: companyId,
            };
            const params = ApiBackend.buildQueryString<typeof paramFilter>(paramFilter);

            return Axios.get(`${ApiBackend.config.backendBaseUrl}b2b/tickets?${params}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data?.status === "OK" ? res.data.tickets : [];
                }).catch((err) => {
                    return [];
                });
        });
    }

    updateSupportTicket(id: string, request: IUpdateSupportTicketRequest): Promise<SupportTicket> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}b2b/tickets/${id}`, request, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data?.status === "OK" ? res.data.ticket : null;
            }).catch((err) => {
                return null;
            });
        });
    }

    initializeSwishPayment(orderId: string): Promise<string> {
        return Axios.get(`${ApiBackend.config.backendBaseUrl}payment/swish/initialize/${orderId}?mobile=false`)
            .then((res) => {
                return res.data?.id;
            }).catch((err) => {
                return null;
            });
    }

    checkSwishPaymentStatus(refId: string): Promise<"PAID" | "DECLINED" | "ERROR" | ""> {
        return Axios.get(`${ApiBackend.config.backendBaseUrl}payment/swish/status/${refId}`)
            .then((res) => {
                if (!res.data?.status) {
                    return "ERROR";
                }

                return res.data.status;
            }).catch((err) => {
                return "ERROR";
            });
    }

    resendStartCertificate(entryId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(`${ApiBackend.config.backendBaseUrl}entries/${entryId}/startcertificate/resend`, {}, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    async validateEntryForRace(entry: Entry, productId: string, productVariantId: string): Promise<IValidationMessage[] | null> {
        const body = {
            entry: entry,
            productId: productId,
            productVariantId: productVariantId
        };

        return Axios.post<IValidateEntryResponse>(ApiBackend.config.backendBaseUrl + "checkout/entry/validate", body).then((res) => {
            if (res?.data?.hasErrors) {
                return res.data.validationMessages;
            }
            return null;
        }).catch((err) => {
            return null;
        });
    }

    async listProductBundles(): Promise<ProductBundle[]> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.get<IListProductBundlesResponse>(buildUrl("products/bundles"), buildConfig(token));
            return response.data?.bundles ?? [];
        } catch (e) {
            return [];
        }
    }

    async getProductBundle(bundleId: string): Promise<ProductBundle> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.get<IGetProductBundleResponse>(buildUrl(`products/bundles/${bundleId}`), buildConfig(token));
            return response.data?.bundle ?? null;
        } catch (e) {
            return null;
        }
    }

    async addOrUpdateProductBundle(bundle: ProductCatalogue): Promise<ProductBundle> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.put<IAddOrUpdateProductBundleResponse>(
                buildUrl(`products/bundles/${bundle.id}`),
                bundle,
                buildConfig(token)
            );
            return response.data?.bundle ?? null;
        } catch (e) {
            return null;
        }
    }

    async getEntryAddonOrderItems(entryId: string): Promise<IAddOnOrderItem[]> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.get<IGetAddOnOrderItemsResponse>(buildUrl(`entries/${entryId}/addonorderitems`), buildConfig(token));

            if (response.data?.status === "OK") {
                return response.data.addOns;
            }

            return [];
        } catch (e) {
            return [];
        }
    }

    async createEntryAllowance(entryId: string,pdfSuffix : string, ssn?: string): Promise<boolean> {
        try {
            const config = { responseType: "arraybuffer" } as any;
            const url = `${ApiBackend.config.backendBaseUrl}binary/entries/${entryId}/wellnesspdf`;
            const response = await Axios.post(url, { socialSecurity: ssn }, config);

            const fileName = `friskvårdskvitto_${pdfSuffix}.pdf`;
            const targetUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = targetUrl;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(targetUrl);

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
    async getAccountingAccounts(fromDate: string, toDate: string, groupBy: "account" | "product") : Promise<AccountingAccountItem[]> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.get<IGetAccountingAccountsResponse>(buildUrl(`orders/accountingaccounts/?fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`), buildConfig(token));

            if (response.data?.status === "OK") {
                return response.data.accounts;
            }

            return [];
        } catch (e) {
            return [];
        }
    }
    async getOrderTransactionsForAccount(account: string, type: "account" | "product", fromDate : string, toDate: string) : Promise<OrderTransactionRow[]> {
        try {
            const token = await ApiBackend.firebase.auth().currentUser.getIdToken();
            const response = await Axios.get<IGetOrderTransactionRowsResponse>(buildUrl(`orders/transactions/?account=${account}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`), buildConfig(token));

            if (response.data?.status === "OK") {
                return response.data.rows;
            }

            return [];
        } catch (e) {
            return [];
        }
    }
}

interface config {
    backendBaseUrl: string;
    checkoutUrl: string;
    minaSidorUrl: string;
    b2bUrl: string;
}

interface IValidateEntryResponse {
    status: "OK" | "ERROR";
    hasErrors: boolean;
    validationMessages?: IValidationMessage[];
}

interface IValidationMessage {
    severity: "error"; // TODO? "info" | "warning" | "error";
    code: "duplicate_entry"
}
