import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Grid, { GridSize } from "@material-ui/core/Grid/Grid";
import React from "react";
import { EntryStatus, EntryStatusTypes, RaceStatusTypes } from "../../../model/CommonTypes";
import { OrderStatuses } from "../../../model/Order";
import BirthDatePicker from "../BirthDatePicker/BirthDatePicker";
import ClearableSelect from "../ClearableSelect";
import CodeHolderCategoryPicker from "../CodeHolderCategoryPicker";
import CompanySelect from "../CompanySelect";
import DebouncedTextField from "../DebouncedTextField";
import StartGroupSelector from "../StartGroupSelector";
import { ProductType } from "../../../model/Product";
import ProductListPopover from "../ProductList/ProductListPopover";
import EventIdFilter from "./EventIdFilter";
import { ProductListChangeEvent } from "../ProductList/utils";
import { Countries } from "../../../providers/countries";
import CompanyCategoryPicker from "../CompanyCategoryPicker";
import { isArray } from "lodash";

export type FilterItemType = "EventId" | "DebouncedText" | "CodeHolderCategory" | "CompanyCategory" | "Checkbox" | "ProductList" | "EntryStatus" | "StartGroup" | "Date" | "OrderStatus" | "Company" | "CountryPicker" | "BirthDate" | "RaceStatus" | "GenericSelect";

export interface IFilterItem {
    id: string;
    label?: string;
    defaultValue?: string | string[] | boolean;
    type: FilterItemType;
    size: number;
    clientFilter?: boolean;
}

export interface IProductFilterItem extends IFilterItem {
    productTypes?: ProductType[];
    useVariants?: boolean;
    multiple: boolean;
}

export interface ICompanyFilterItem extends IFilterItem {
    includeArchived?: boolean;
    multiple?: boolean;
}

export interface IGenericSelectFilterItem extends IFilterItem {
    itemDefinition: {
        itemValueType: "string" | "number" | "boolean"
        items: { name: string; value: string | number | boolean; }[];
    }
    clearable?: boolean;
}

export interface IEntryStatusFilterItem extends IFilterItem {
    restrictedValues?: EntryStatus[];
    multiple?: boolean;
}

type FilterItemUnion = IFilterItem | IProductFilterItem | ICompanyFilterItem | IGenericSelectFilterItem | IEntryStatusFilterItem;

export type FilterValue = string | string[] | boolean;

export type Filters<T> = Record<keyof T, FilterValue>;

interface IProps<T> {
    id: string;
    filters: Partial<Record<keyof T, FilterItemUnion>>;
    persist?: boolean;
    onInit?: (filter: Filters<T>) => void;
    onChange: (filter: Filters<T>) => void;
}

interface IState<T> {
    key: number;
    filters: Record<keyof T, IFilterItem>;
    searchFilter: Filters<T>;
    mounted: boolean;
    selEventId?: string;
    selProductId?: string | string[];
}

class SearchFilter<T> extends React.Component<IProps<T>, IState<T>> {
    state: IState<T>;

    constructor(props: IProps<T>) {
        super(props);

        this.state = {
            key: this.getNewTime(),
            filters: props.filters as any,
            searchFilter: {} as any,
            mounted: false
        };
    }

    componentDidMount(): void {
        let searchFilter = this.getSearchFilter();

        if (!searchFilter) {
            searchFilter = this.buildDefaultSearchFilter();
        }

        let selEventId: string;
        if (searchFilter) {
            selEventId = this.getEventPeriodFilterValue(searchFilter);
        }

        let selProductId: string | string[];
        if (searchFilter) {
            selProductId = this.getProductListFilterValue(searchFilter);
        }

        this.setState({ searchFilter: searchFilter, selEventId: selEventId, selProductId: selProductId, mounted: true }, () => {
            this.notifyInit();
        });
    }

    render(): JSX.Element {
        const { id } = this.props;
        const { key, mounted } = this.state;

        if (!mounted) {
            return null;
        }

        return (
            <Grid id={id} key={key} container spacing={2}>
                {this.renderItems()}
                <Grid item xs={12}>
                    <Button variant="text"
                        onClick={() => {
                            this.resetFilter();
                        }}
                    >Rensa Filter</Button>
                </Grid>
            </Grid>
        );
    }

    private renderItems(): JSX.Element[] {
        const { selEventId, selProductId, filters, searchFilter } = this.state;

        const startGroupSelectorProperty = (): keyof T | undefined => {
            for (const propName in filters) {
                if (filters[propName].type === "StartGroup") {
                    return propName;
                }
            }

            return undefined;
        };

        const buildDebounced = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <DebouncedTextField id={filter.id}
                        initialValue={filterValue}
                        label={filter.label}
                        style={{ flex: 1, margin: '0 3px' }}
                        onChange={(value: string) => {
                            this.handleChange(key, value);
                        }}
                    />
                </Grid>
            );
        };

        const buildCodeHolderCategoryPicker = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string | string[];
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <CodeHolderCategoryPicker
                        strict={true}
                        multiple={true}
                        initialValue={filterValue}
                        onChange={(value: string[]) => {
                            this.handleChange(key, value);
                        }}
                    />
                </Grid>
            );
        };

        const buildCompanyCategoryPicker = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string | string[];
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <CompanyCategoryPicker
                        strict={true}
                        multiple={true}
                        initialValue={filterValue}
                        onChange={(value: string[]) => {
                            this.handleChange(key, value);
                        }}
                    />
                </Grid>
            );
        };

        const buildCheckBox = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as boolean;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControlLabel
                        control={
                            <Checkbox name="filter-cbx" color="primary"
                                checked={filterValue}
                                onChange={() => {
                                    this.handleChange(key, !filterValue);
                                }}
                            />
                        }
                        label={filter.label}
                    />
                </Grid>
            );
        };

        const buildEventId = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <EventIdFilter
                            label={filter.label}
                            useWildCardSelect={true}
                            initialValue={filterValue}
                            onChange={(value) => {
                                this.setState({ selEventId: value }, () => {
                                    this.handleChange(key, value);
                                })
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        };

        const buildProductList = (key: keyof T, filter: IProductFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string | string[];

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <ProductListPopover
                        label={filter.label}
                        productTypes={filter.productTypes}
                        useVariants={filter.useVariants}
                        filterEventId={selEventId}
                        initialValue={filterValue}
                        clearable
                        multiple={filter.multiple}
                        singleSelect={!filter.multiple}
                        onClear={() => {
                            this.setState({ selProductId: undefined }, () => {
                                this.handleChange(key, "");
                                const startGroupPropery = startGroupSelectorProperty();
                                if (startGroupPropery) {
                                    this.handleChange(startGroupPropery, "");
                                }
                            });
                        }}
                        onChange={(evt: ProductListChangeEvent) => {
                            const productIds = evt.products.map(p => p.id).filter(Boolean);
                            this.setState({ selProductId: productIds }, () => {
                                if (filter.useVariants) {
                                    let ids: string[] = [];
                                    for (let i = 0; i < evt.products.length; i++) {
                                        const p = evt.products[i];
                                        ids.push(...p.variantIds);
                                    }
                                    this.handleChange(key, ids);
                                } else {
                                    this.handleChange(key, productIds);
                                }
                            });
                        }}
                    />
                </Grid>
            );
        };

        const buildStartGroupSelector = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string | string[];
            const subKey = Array.isArray(selProductId) ? selProductId.join(";") : selProductId;

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <StartGroupSelector
                            key={subKey}
                            productIds={selProductId}
                            initialValue={filterValue}
                            clearable={true}
                            multiple={true}
                            onClear={() => {
                                this.handleChange(key, "");
                            }}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        };

        const buildEntryStatus = (key: keyof T, filter: IEntryStatusFilterItem): JSX.Element => {
            const statuses = filter.restrictedValues ? filter.restrictedValues : EntryStatusTypes;

            let safeValue: string | string[] = searchFilter[key] as string ?? "";
            if (filter.multiple && !Array.isArray(safeValue)) {
                safeValue = safeValue ? [safeValue] as string[] : [];
            }

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <ClearableSelect
                            clearable={true}
                            multiple={filter.multiple}
                            value={safeValue}
                            onClear={() => {
                                if (filter.multiple) {
                                    this.handleChange(key, []);
                                } else {
                                    this.handleChange(key, "");
                                }
                            }}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        >
                            {statuses.map((x, idx) => {
                                return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                            })}
                        </ClearableSelect>
                    </FormControl>
                </Grid>
            );
        };

        const buildOrderStatus = (key: keyof T, filter: IFilterItem): JSX.Element => {
            let filterValue = searchFilter[key] as string[];
            if (!isArray(filterValue))
            {
                filterValue = filterValue ? [filterValue] : [];

            }

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <Select
                            multiple={true}
                            value={filterValue}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        >
                            {OrderStatuses.map((x, idx) => {
                                return <MenuItem key={idx} value={x.value}>{x.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            );
        };

        const buildDate = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <Input
                            type="date"
                            value={filterValue}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        };

        const buildCompany = (key: keyof T, filter: ICompanyFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string | string[];

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <CompanySelect
                            clearable={true}
                            multiple={filter.multiple}
                            initialValue={filterValue}
                            includeArchived={filter.includeArchived}
                            onClear={() => {
                                this.handleChange(key, "");
                            }}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        };

        const buildCountryPicker = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            const allCountries = Countries.getCountries();

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <ClearableSelect
                            clearable={true}
                            value={filterValue}
                            onClear={() => {
                                this.handleChange(key, "");
                            }}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        >
                            <MenuItem key="-1" value="SWE">Sverige</MenuItem>;
                            <MenuItem key="-2" value="NOR">Norge</MenuItem>;
                            <MenuItem key="-3" value="FIN">Finland</MenuItem>;
                            <MenuItem key="-4" value="DEN">Danmark</MenuItem>;
                            {allCountries.map((country, idx) => {
                                return <MenuItem key={idx} value={country.abbr}>{country.name}</MenuItem>;
                            })}
                        </ClearableSelect>
                    </FormControl>
                </Grid>
            );
        };

        const buildBirthDatePicker = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;

            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <InputLabel shrink>{filter.label}</InputLabel>
                    <BirthDatePicker
                        initialValue={filterValue}
                        onChange={(value) => {
                            this.handleChange(key, value);
                        }}
                    />
                </Grid>
            );
        };

        const buildRaceStatus = (key: keyof T, filter: IFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <ClearableSelect
                            clearable={true}
                            value={filterValue}
                            onClear={() => {
                                this.handleChange(key, "");
                            }}
                            onChange={(evt: any) => {
                                this.handleChange(key, evt.target.value);
                            }}
                        >
                            {RaceStatusTypes.map((x, idx) => {
                                return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                            })}
                        </ClearableSelect>
                    </FormControl>
                </Grid>
            );
        };

        const buildGenericSelect = (key: keyof T, filter: IGenericSelectFilterItem): JSX.Element => {
            const filterValue = searchFilter[key] as string;
            return (
                <Grid key={filter.id} item xs={filter.size as GridSize}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{filter.label}</InputLabel>
                        <ClearableSelect
                            clearable={filter.clearable}
                            value={filterValue ?? ""}
                            onClear={() => {
                                this.handleChange(key, "");
                            }}
                            onChange={(evt: any) => {
                                let value = evt.target.value;
                                if (filter.itemDefinition.itemValueType === "boolean") {
                                    value = value === "true";
                                } else if (filter.itemDefinition.itemValueType === "number") {
                                    value = +value;
                                }

                                this.handleChange(key, value);
                            }}
                        >
                            {filter.itemDefinition.items.map((x, idx) => {
                                return <MenuItem key={idx} value={`${x.value}`}>{x.name}</MenuItem>;
                            })}
                        </ClearableSelect>
                    </FormControl>
                </Grid>
            );
        };

        const items: JSX.Element[] = [];
        for (const propName in filters) {
            switch (filters[propName].type) {
                case "DebouncedText":
                    items.push(buildDebounced(propName, filters[propName]))
                    break;
                case "CodeHolderCategory":
                    items.push(buildCodeHolderCategoryPicker(propName, filters[propName]))
                    break;
                case "CompanyCategory":
                    items.push(buildCompanyCategoryPicker(propName, filters[propName]))
                    break;
                case "Checkbox":
                    items.push(buildCheckBox(propName, filters[propName]))
                    break;
                case "EventId":
                    items.push(buildEventId(propName, filters[propName]))
                    break;
                case "ProductList":
                    items.push(buildProductList(propName, filters[propName] as IProductFilterItem))
                    break;
                case "StartGroup":
                    items.push(buildStartGroupSelector(propName, filters[propName]))
                    break;
                case "EntryStatus":
                    items.push(buildEntryStatus(propName, filters[propName]))
                    break;
                case "OrderStatus":
                    items.push(buildOrderStatus(propName, filters[propName]))
                    break;
                case "Date":
                    items.push(buildDate(propName, filters[propName]))
                    break;
                case "Company":
                    items.push(buildCompany(propName, filters[propName] as ICompanyFilterItem))
                    break;
                case "CountryPicker":
                    items.push(buildCountryPicker(propName, filters[propName]))
                    break;
                case "BirthDate":
                    items.push(buildBirthDatePicker(propName, filters[propName]))
                    break;
                case "RaceStatus":
                    items.push(buildRaceStatus(propName, filters[propName]))
                    break;
                case "GenericSelect":
                    items.push(buildGenericSelect(propName, filters[propName] as IGenericSelectFilterItem))
                    break;
                default:
                    break;
            }
        }

        return (items);
    }

    private handleChange = (key: keyof T, value: FilterValue): void => {
        let { searchFilter } = this.state;

        searchFilter[key] = value;
        this.setSearchFilter(searchFilter);
    };

    private setSearchFilter = (searchFilter: Filters<T>, cb?: () => void): void => {
        const { persist } = this.props;
        this.setState({ searchFilter }, () => {
            if (persist) {
                this.saveSearchFilter();
            }

            this.notifyChange();

            if (cb) {
                cb();
            }
        });
    };

    private buildDefaultSearchFilter = (): Filters<T> => {
        const { filters } = this.state;
        let searchFilter: any = {};

        const defaultValue = (filterItem: IFilterItem): string | string[] | boolean | undefined => {
            if (filterItem.defaultValue) {
                return filterItem.defaultValue;
            }

            switch (filterItem.type) {
                case "Checkbox":
                    return false;
                case "CodeHolderCategory":
                    return null;
                default:
                    return "";
            }
        };

        for (const propName in filters) {
            searchFilter[propName] = defaultValue(filters[propName]);
        }

        return searchFilter;
    };

    private getEventPeriodFilterValue = (searchFilter: Filters<T>): string | undefined => {
        const { filters } = this.state;

        for (const propName in filters) {
            if (filters[propName].type === "EventId") {
                return searchFilter[propName] as string;
            }
        }

        return undefined
    };

    private getProductListFilterValue = (searchFilter: Filters<T>): string | string[] | undefined => {
        const { filters } = this.state;

        for (const propName in filters) {
            if (filters[propName].type === "ProductList") {
                if (Array.isArray(searchFilter[propName])) {
                    return searchFilter[propName] as string[];
                }

                return searchFilter[propName] as string;
            }
        }

        return undefined
    };

    private saveSearchFilter = (): void => {
        const { id } = this.props;
        const { searchFilter } = this.state;

        localStorage.setItem(id, JSON.stringify(searchFilter));
    };

    private getSearchFilter = (): Filters<T> | undefined => {
        const { id } = this.props;
        const filter = localStorage.getItem(id);

        if (filter) {
            return JSON.parse(filter);
        }
    };

    private resetFilter = (): void => {
        const nextFilter = this.buildDefaultSearchFilter();
        this.setSearchFilter(nextFilter, () => {
            this.setState({ key: this.getNewTime() })
        });
    };

    private getNewTime = (): number => {
        return new Date().getTime();
    }

    private notifyInit = (): void => {
        const { onInit } = this.props;

        if (!onInit) {
            return;
        }

        const { searchFilter } = this.state;
        onInit(searchFilter);
    };

    private notifyChange = (): void => {
        const { onChange } = this.props;
        const { filters, searchFilter } = this.state;

        let serverFilters: Filters<T> = {} as any;
        for (const propName in filters) {
            const filter = filters[propName];
            if (!filter.clientFilter && searchFilter[propName]) {
                serverFilters[propName] = searchFilter[propName]
            }
        }

        onChange(serverFilters);
    };
}

export default SearchFilter;
