import React, { useRef, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import { RouteComponentProps, withRouter } from "react-router";
import SearchFilter, { Filters } from "../Common/SearchFilter/SearchFilter";
import { ApiBackend } from "../../providers/apibackend";
import { ListEntriesParameters } from "../../providers/models";
import { ListEntry } from "../../model/Entry";
import EnhancedTable from "../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition, ISortModel } from "../Common/EnhancedTable/models";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";
import useDidMountEffect from "../Common/UseDidMountEffect";
import CreateEntryDialog from "../Entries/Entry/CreateEntryDialog/CreateEntryDialog";
import { EntryStatus } from "../../model/CommonTypes";
import PatchEntriesDialog from "../Entries/Entry/PatchEntries/PatchEntriesDialog";
import { listEntriesToPatchEntryDocumentProps } from "../Entries/Entry/PatchEntries/utils";
import { searchFilterToExportExcelFilter } from "../Common/SearchFilter/utils";
import { IExportExcelRequest } from "../../model/IExportExcelRequest";
import { ArrowForward, Edit, OpenInBrowser } from "@material-ui/icons";
import EditEntryDialog from "../Entries/Entry/EditEntryDialog";

interface ISearchFilter {
    lastName?: string;
    firstName?: string;
    email?: string;
    phone?: string;
    status?: EntryStatus;
    nationality?: string;
    productVariant?: string[];
}

type editEntryMode = "dialog" | "in-app" | "new-tab";

const ManageAccreditations = ({ classes, history }: RouteComponentProps & WithStyles) => {
    const [loading, setLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null as ISearchFilter);
    const [entries, setEntries] = useState(null as ListEntry[]);
    const [sortModel, setSortModel] = useState({ sortBy: "orderDate", sortOrder: "desc" } as ISortModel<ListEntry>);
    const [selected, setSelected] = React.useState<ListEntry[]>([]);
    const [showCreateNewEntry, setShowCreateNewEntry] = useState(false);
    const [editEntry, setEditEntry] = useState(null as ListEntry);
    const [showPatch, setShowPatch] = useState(false);

    const busyLoading = useRef<boolean>(false);
    const backend = new ApiBackend();
    const maxRows = 500;

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useDidMountEffect(() => {
        performSearch();
    }, [searchFilter]);

    const performSearch = async (): Promise<void> => {
        if (busyLoading.current) {
            return;
        }

        setBusyLoading(true);

        try {
            const params: ListEntriesParameters = {
                ...searchFilter,
                productType: ["acred"],
                begin: 0,
                limit: maxRows
            };

            const response = await backend.listEntries(params);
            setEntries(response.entries);
        } finally {
            setBusyLoading(false);
        }
    };

    const handleExport = async () => {
        const filter = searchFilterToExportExcelFilter<ISearchFilter>(searchFilter);

        const request: IExportExcelRequest = {
            entityType: "Ackreditation",
            filter: filter,
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder
        };
        try {
            setLoading(true);
            await backend.exportExcel(request);
        } finally {
            setLoading(false);
        }
    };

    const handleRequestSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        setSearchFilter(searchFilter);
    };

    const handleEditEntry = (entry: ListEntry, mode: editEntryMode) => (e: React.MouseEvent<unknown>): void => {
        e.stopPropagation();
        switch (mode) {
            case "dialog":
                setEditEntry(entry);
                break;
            case "in-app":
                history.push(`/entries/manage/${entry.id}`);
                break;
            case "new-tab":
                window.open(`/entries/manage/${entry.id}`);
                break;
        }
    };

    const columnDefinitions: IColumnDefinition<ListEntry>[] = [
        {
            renderCell: (row) => {
                return <>
                    <IconButton color="default" size="small"
                        onClick={handleEditEntry(row, "dialog")}
                    >
                        <Edit fontSize="medium" />
                    </IconButton>
                    <IconButton color="default" size="small"
                        onClick={handleEditEntry(row, "new-tab")}
                    >
                        <OpenInBrowser fontSize="medium" />
                    </IconButton>
                    <IconButton color="default" size="small"
                        onClick={handleEditEntry(row, "in-app")}
                    >
                        <ArrowForward fontSize="medium" />
                    </IconButton>
                </>
            }
        },
        { propName: "lastName", label: "Efternamn" },
        { propName: "firstName", label: "Förnamn" },

        { propName: "email", label: "E-post" },
        { propName: "phone", label: "Telefon" },
        { propName: "nationality", label: "Nationalitet" },
        {
            propName: "orderDate",
            label: "Orderdatum",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---")
        },
        { propName: "eventName", label: "Produkt" },
        { propName: "status", label: "Status" }
    ];

    const defaultBoxStyle = {
        display: "flex",
        flexDirection: "row",
    };

    const render = () => {
        const isPatchDisabled = !selected || selected.length === 0;

        return (<>
            <SearchFilter<ISearchFilter>
                id="manage-accreditations-filter-2"
                filters={{
                    "lastName": {
                        id: "filter-last-name",
                        type: "DebouncedText",
                        label: "Efternamn",
                        size: 4
                    },
                    "firstName": {
                        id: "filter-first-name",
                        type: "DebouncedText",
                        label: "Förnamn",
                        size: 4
                    },
                    "email": {
                        id: "filter-email",
                        type: "DebouncedText",
                        label: "E-post",
                        size: 4
                    },
                    "phone": {
                        id: "filter-phone",
                        type: "DebouncedText",
                        label: "Telefon",
                        size: 4
                    },
                    "nationality": {
                        id: "filter-nationality",
                        type: "CountryPicker",
                        label: "Nationalitet",
                        size: 4
                    },
                    "productVariant": {
                        id: "filter-productVariant",
                        type: "ProductList",
                        label: "Produkt",
                        size: 4,
                        productTypes: ["acred"],
                        useVariants: true,
                        multiple: true
                    },
                    "status": {
                        id: "filter-status",
                        type: "EntryStatus",
                        label: "Status",
                        restrictedValues: ["REQUEST", "PENDING", "REJECTED", "ACTIVE"],
                        multiple: true,
                        size: 4,
                        defaultValue: ["REQUEST", "PENDING"]
                    }
                }}
                persist={true}
                onInit={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <>
                                    <Typography variant="h5" style={{ display: "inline" }}>Ackrediteringar </Typography>
                                    <RefreshIcon
                                        style={{ display: "inline", verticalAlign: "middle" }}
                                        onClick={performSearch}
                                    />
                                </>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <div style={{ marginTop: 12, marginRight: 12 }}>
                                        <GetAppIcon onClick={handleExport} />
                                    </div>
                                </Box>
                            }
                        />
                        <CardContent>
                            <EnhancedTable<ListEntry>
                                columnDefinitions={columnDefinitions}
                                data={entries ?? []}
                                pageSize={10}
                                maxRows={maxRows}
                                paginationMode="client"
                                sortingMode="client"
                                sortModel={sortModel}
                                onSortModelChange={(nextSortModel: ISortModel<ListEntry>) => {
                                    setSortModel(nextSortModel);
                                }}
                                loading={loading}
                                dense
                                selectable
                                onSelectChanged={(rows: ListEntry[]) => {
                                    setSelected(rows);
                                }}
                            />
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                onClick={() => {
                                    setShowCreateNewEntry(true);
                                }}
                            >
                                Skapa ny
                            </Button>
                            <Button size="small" color="secondary"
                                disabled={isPatchDisabled}
                                onClick={() => {
                                    setShowPatch(true);
                                }}
                            >
                                Ändra status för markerade
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            {showCreateNewEntry &&
                <CreateEntryDialog
                    restrictedProductType="acred"
                    onComplete={() => {
                        setShowCreateNewEntry(false);
                        performSearch();
                    }}
                    onAbort={() => {
                        setShowCreateNewEntry(false);
                    }}
                />
            }

            {showPatch &&
                <PatchEntriesDialog
                    items={listEntriesToPatchEntryDocumentProps(selected)}
                    patchProperties={["status"]}
                    restrictions={{
                        status: ["REQUEST", "PENDING", "REJECTED", "ACTIVE"]
                    }}
                    title="Masshantera status för anmälningar"
                    onComplete={() => {
                        setShowPatch(false);
                        performSearch();
                    }}
                    onAbort={() => {
                        setShowPatch(false);
                    }}
                />
            }

            {editEntry &&
                <EditEntryDialog
                    entryId={editEntry.id}
                    onSave={() => {
                        setEditEntry(null);
                        performSearch();
                    }}
                    onAbortEdit={() => {
                        setEditEntry(null);
                    }}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(ManageAccreditations));
