import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ExportViewProps, ExportViewRef } from "./models";
import SearchFilter, { Filters } from "../../../Common/SearchFilter/SearchFilter";
import { searchFilterToExportExcelFilter } from "../../../Common/SearchFilter/utils";
import useDidMountEffect from "../../../Common/UseDidMountEffect";

type SearchFilterType = {
    productId?: string;
};

interface ISearchFilter {
    productId?: string;
}

const PersonalBibNumber = forwardRef<ExportViewRef, ExportViewProps>((props, ref) => {
    const [searchFilter, setSearchFilter] = useState(null as ISearchFilter);

    useImperativeHandle(ref, () => ({
        isValid: () => {
            return !!searchFilter?.productId;
        }
    }));

    useDidMountEffect(() => {
        props.onChange(searchFilterToExportExcelFilter<ISearchFilter>(searchFilter));
    }, [searchFilter]);

    const render = () => {
        return (
            <SearchFilter<SearchFilterType>
                id={"export-personalbibnumber-filter"}
                filters={{
                    "productId": {
                        id: "filter-productId",
                        type: "ProductList",
                        label: "Lopp",
                        size: 12,
                        productTypes: ["race"],
                        useVariants: false,
                        multiple: false
                    }
                }}
                persist={true}
                onInit={(filter: Filters<SearchFilterType>) => {
                    setSearchFilter(filter as unknown as ISearchFilter);
                }}
                onChange={(filter: Filters<SearchFilterType>) => {
                    setSearchFilter(filter as unknown as ISearchFilter);
                }}
            />
        );
    };

    return render();
});

export default PersonalBibNumber;
