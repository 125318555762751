/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TableCell, TextField, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { cloneDeep } from 'lodash';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import uuidv4 from "react-uuid";
import { CodeHolder } from '../../model/CodeHolder';
import FirebaseContext from '../../providers/Firebase/context';
import { ApiBackend } from '../../providers/apibackend';
import EnhancedTable from '../Common/EnhancedTable/EnhancedTable';
import { IColumnDefinition } from '../Common/EnhancedTable/models';
import SearchFilter, { Filters } from '../Common/SearchFilter/SearchFilter';
import withToaster, { IToasterContext } from '../Common/Toaster';
import withConfirm, { IConfirmContext } from '../Common/dialog/Confirm';
import { vasaloppetDateGetterFormatter } from '../../utilities/date';

type IProps = RouteComponentProps & IToasterContext & IConfirmContext;

interface ISearchFilter {
    codeHolderName?: string;
    codeHolderDesc?: string;
    codeHolderCreatedBy?: string;
    product?: string;
    code?: string;
    category?: string[];
    includeArchived: boolean;
}

interface ICopyItem {
    sourceId: string;
    name: string;
    description: string;
}

interface State {
    loading: boolean;
    codes: CodeHolder[];
    searchFilter: ISearchFilter;
    copyItem?: ICopyItem;
}

class ManageCodes extends React.Component<IProps, State> {
    static contextType = FirebaseContext;
    state: State;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);
        this.api = new ApiBackend();

        this.state = {
            loading: true,
            codes: null,
            searchFilter: {
                codeHolderName: "",
                codeHolderDesc: "",
                code: "",
                category: ["B2B Portal köp", "ESK-ANMÄLAN", "Eventkoder", "Manuell anmälan", "Vasaloppet internt"],
                includeArchived: false,
            }
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
    }

    render(): JSX.Element {
        const { classes } = this.props as any;
        const { searchFilter } = this.state;

        const usageNumFormatter = (codeHolder: CodeHolder): number => {
            if (!codeHolder.NumUsed) {
                return 0;
            }

            if (!codeHolder.NumAvailable) {
                return codeHolder.NumUsed;
            }

            return codeHolder.NumUsed / codeHolder.NumAvailable;
        };
        const usageFormatter = (codeHolder: CodeHolder) => `${codeHolder.NumUsed ?? 0} / ${codeHolder.NumAvailable ?? 0}`;
        const usageComparator = (a: CodeHolder, b: CodeHolder) => usageNumFormatter(a) - usageNumFormatter(b);

        const columnDefinitions: IColumnDefinition<CodeHolder>[] = [{
            propName: "name",
            label: "Namn",
            onCellClick: (row, column) => {
                this.editCode(row);
            },
        }, {
            propName: "ValidFrom",
            label: "Giltig från",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.ValidFrom)
        }, {
            propName: "ValidTo",
            label: "Giltig till",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.ValidTo)
        }, {
            propName: "NumUsed",
            label: "Nyttjande",
            valueFormatter: (row) => usageFormatter(row),
            sortComparator: (usageComparator)
        }, {
            propName: "category",
            label: "Kategori"
        }, {
            propName: "createdBy",
            label: "Skapad av",
            valueFormatter: (row) => row.createdBy ?? "---"
        }, {
            propName: "created",
            label: "Skapad",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.created, "---")
        }, {
            propName: "updatedBy",
            label: "Uppdaterad av",
            valueFormatter: (row) => row.updatedBy ?? "---"
        }, {
            propName: "updated",
            label: "Uppdaterad",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.updated, "---")
        }, {
            align: "right",
            renderCell: (row) => {
                return (<>
                    {row.category !== "B2B Portal köp" &&
                        <FileCopyIcon
                            onClick={this.createCopy(row)}
                            className={classes.icon}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                    <EditIcon onClick={() => {
                        this.editCode(row);
                    }} className={classes.icon} style={{ cursor: 'pointer' }} />
                    <DeleteIcon onClick={() => this.deleteCode(row)} className={classes.icon} style={{ cursor: 'pointer' }} />
                </>)
            }
        }];

        return <>
            <SearchFilter
                id={"manage-codes-filter-2"}
                filters={{
                    "codeHolderName": {
                        id: "filter-codeholder-name",
                        type: "DebouncedText",
                        label: "Namn på kodhållare",
                        size: 4
                    },
                    "code": {
                        id: "filter-code-name",
                        type: "DebouncedText",
                        label: "Namn på kod",
                        size: 4
                    },
                    "category": {
                        id: "filter-code-category",
                        type: "CodeHolderCategory",
                        label: "Kategori",
                        size: 4,
                        defaultValue: searchFilter.category
                    },
                    "codeHolderDesc": {
                        id: "filter-codeholder-description",
                        type: "DebouncedText",
                        label: "Beskrivning på kodhållare",
                        size: 4
                    },
                    "codeHolderCreatedBy": {
                        id: "filter-codeholder-created-by",
                        type: "DebouncedText",
                        label: "Skapad av",
                        size: 4
                    },
                    "product": {
                        id: "filter-product",
                        type: "ProductList",
                        label: "Lopp",
                        size: 4,
                        multiple: true
                    },
                    "includeArchived": {
                        id: "filter-includearchived",
                        type: "Checkbox",
                        label: "Inkludera arkiverade",
                        size: 4
                    }
                }}
                persist={true}
                onInit={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Koder" />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={this.addCode}>Lägg till kod</Button>
                                </Grid>
                                <Grid item xs={5}>
                                </Grid>
                                <Grid item xs={12}>
                                    <EnhancedTable<CodeHolder>
                                        columnDefinitions={columnDefinitions}
                                        data={this.state.codes ?? []}
                                        pageSize={10}
                                        sortModel={{
                                            sortBy: "created",
                                            sortOrder: "desc"
                                        }}
                                        loading={this.state.loading}
                                        dense
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.copyItem && this.renderCreateCopyDialog()}
        </>;
    }

    private renderCreateCopyDialog(): JSX.Element {
        const { copyItem } = this.state;

        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Skapa kopia av Kodhållaren</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">Nytt namn</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                            value={copyItem.name}
                            onChange={(ev) => {
                                copyItem.name = ev.target.value;
                                this.setState({ copyItem: copyItem });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">Ny beskrivning</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                            value={copyItem.description}
                            onChange={(ev) => {
                                copyItem.description = ev.target.value;
                                this.setState({ copyItem: copyItem });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleCreateCopyCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleCreateCopyOk} color="primary">
                    Ja, skapa kopia
                </Button>
            </DialogActions>
        </Dialog>
    }

    private addCode = (): void => {
        this.props.history.push("/codes/create");
    };

    private editCode = (codeHolder: CodeHolder) => {
        this.props.history.push("/codes/manage/" + codeHolder.id);
    };

    private deleteCode = async (codeHolder: CodeHolder) => {
        const result = await this.props.showConfirm(`Är du säker på att du vill ta bort kodhållaren (${codeHolder.name})?`);
        if (!result) {
            return;
        }

        this.setState({ loading: true });

        try {
            const res = await this.api.deleteCodeHolder(codeHolder);
            if (res) {
                await this.fetchCodeHolders();
            }
            else {
                this.props.showToast("Kunde inte ta bort kodhållare", "error");
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    private createCopy = (codeHolder: CodeHolder) => () => {
        this.setState({
            copyItem: {
                sourceId: codeHolder.id,
                name: `Kopia av ${codeHolder.name}`,
                description: codeHolder.description
            }
        });
    };

    private handleCreateCopyCancel = () => {
        this.setState({ copyItem: null });
    }

    private handleCreateCopyOk = async () => {
        const { copyItem } = this.state;

        this.setState({ loading: true });

        const source = await this.api.getCode(copyItem.sourceId);

        const clone = cloneDeep(source);
        clone.id = uuidv4();
        clone.name = copyItem.name;
        clone.description = copyItem.description;
        clone.Codes = null;
        clone.b2bCompany = null;
        clone.created = null;
        clone.updated = null;
        clone.createdBy = null;
        clone.updatedBy = null;
        clone.IsArchived = false;

        clone.Implementations.forEach(x => {
            x.id = uuidv4();
        });

        const res = await this.api.createOrUpdateCode(clone);
        this.setState({ loading: false, copyItem: null });

        if (res) {
            this.props.history.push(`/codes/manage/${clone.id}`);
        }
        else {
            this.props.showToast("Kunde inte skapa kodhållare!");
        }
    }

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, loading: true }, async () => {
            await this.fetchCodeHolders();
        });
    };

    private fetchCodeHolders = async (): Promise<void> => {
        const { searchFilter } = this.state;
        const codeHolders = await this.api.listCodes(searchFilter);
        this.setState({ codes: codeHolders, loading: false });
    };

}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withConfirm(withToaster(withStyles(useStyles)(ManageCodes))));
