import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@material-ui/core";
import { Order } from "../../../model/Order";
import { Alert } from "@material-ui/lab";
import { OrderItem } from "../../../model/OrderItem";
import { ApiBackend } from "../../../providers/apibackend";
import { canCreateEntryAllowanceOrder, canCreateEntryAllowanceOrderItem, filterCreateableEntryAllowanceOrderItems } from "./utils";

interface IProps {
    mode: "ORDER" | "ORDERITEM";
    order: Order;
    orderItem?: OrderItem;
    onComplete: () => void;
    onAbort: () => void;
}

const CreateAllowanceDialog = (props: IProps) => {
    const [socialSecurity, setSocialSecurity] = useState("");
    const [orderItemId, setOrderItemId] = useState(props.orderItem?.Id);
    const [isCreating, setIsCreating] = useState(false);
    const [pdfSuffix, setPdfSuffix] = useState("");

    const backend = new ApiBackend();

    const createAllowance = async (): Promise<void> => {
        setIsCreating(true);
        await backend.createEntryAllowance(orderItemId, pdfSuffix, socialSecurity);
        setIsCreating(false);
        props.onComplete();
    };

    const canCreateAllowance = (): boolean => {
        return props.mode === "ORDER" ? canCreateEntryAllowanceOrder(props.order)
            : canCreateEntryAllowanceOrderItem(props.order, props.orderItem);
    }

    const renderForOrder = () => {
        const canCreate = canCreateAllowance();
        const applicableItems = filterCreateableEntryAllowanceOrderItems(props.order);

        return (
            <Grid container spacing={2}>
                {!canCreate &&
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Det finns inga orderrader i denna order som är möjliga att skapa friskvårdskvitto för
                        </Alert>
                    </Grid>
                }

                {canCreate &&
                    <Grid item xs={12}>
                        <TextField
                            select
                            style={{ flex: 1, margin: '0 3px' }}
                            label="Välj orderrad att skapa kvitto för"
                            InputLabelProps={{ shrink: true }}
                            value={orderItemId ?? ""}
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setOrderItemId(e.target.value);
                                const oi = props.order.items.find(x=>x.Id == e.target.value);
                                if (oi && oi.entry)
                                {
                                    setPdfSuffix(oi.entry.firstName + "_" + oi.entry.lastName);
                                }
                                else 
                                {
                                    setPdfSuffix(props.order.publicId);
                                }
                            }}
                        >
                            {applicableItems.map((p, idx) => {
                                return <MenuItem key={idx} value={p.Id}>{p.productName}</MenuItem>;
                            })}
                        </TextField>
                    </Grid>
                }
            </Grid>
        );
    };

    const renderForOrderItem = () => {
        if (!canCreateAllowance()) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Denna orderrad är inte möjlig att skapa friskvårdskvitto för
                        </Alert>
                    </Grid>
                </Grid>
            );
        }

        return null;
    };

    const render = () => {
        const title = props.mode === "ORDER" ? "Friskvårdskvitto för order" : "Friskvårdskvitto";
        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="add-relay-member-title"
                open={true}
            >
                <DialogTitle id="add-relay-member-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: 300 }}>
                    {props.mode === "ORDER" && renderForOrder()}
                    {props.mode === "ORDERITEM" && renderForOrderItem()}
                    <Grid container spacing={2} style={{ marginTop: 1 }}>
                        <Grid item xs={12}>
                            <TextField label="Personnummer (valfritt)"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={socialSecurity}
                                onChange={(ev) => {
                                    setSocialSecurity(ev.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Box style={{ display: "flex", width: "100%", height: "40px" }}>
                        {isCreating && <Box style={{ width: 200 }}>
                            <CircularProgress color="secondary" />
                        </Box>
                        }
                        <Box style={{ display: "flex", flex: 1, gap: 8, justifyContent: "flex-end" }}>
                            <Button
                                variant="contained"
                                disabled={isCreating || !orderItemId}
                                onClick={() => { createAllowance() }}
                                color="secondary">
                                Skapa kvitto
                            </Button>
                            <Button
                                variant="contained"
                                disabled={isCreating}
                                onClick={() => props.onAbort()}
                                color="secondary">
                                Avbryt
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
};

export default CreateAllowanceDialog;
