/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, Grid, IconButton, LinearProgress, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import { ArrowForward, Edit, OpenInBrowser } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { debounce } from 'lodash';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { B2bCompany } from '../../../model/B2bCompany';
import { ListEntry } from '../../../model/Entry';
import { IExportExcelRequest } from '../../../model/IExportExcelRequest';
import { Order } from '../../../model/Order';
import FirebaseContext from '../../../providers/Firebase/context';
import { ApiBackend } from '../../../providers/apibackend';
import SearchFilter, { Filters } from '../../Common/SearchFilter/SearchFilter';
import { searchFilterToExportExcelFilter } from '../../Common/SearchFilter/utils';
import withConfirm, { IConfirmContext } from '../../Common/dialog/Confirm';
import EditEntryDialog from './EditEntryDialog';
import { vasaloppetDateTimeGetterFormatter } from '../../../utilities/date';
import { ListEntriesParameters } from '../../../providers/models';
import { IColumnDefinition, ISortModel, PageSize } from '../../Common/EnhancedTable/models';
import EnhancedTable from '../../Common/EnhancedTable/EnhancedTable';
import { EntryStatus } from '../../../model/CommonTypes';

interface ISearchFilter {
    lastName?: string;
    firstName?: string;
    productVariant?: string[];
    bib?: string;
    startgroup?: string[];
    vasaId?: string;
    birthDate?: string;
    club?: string;
    email?: string;
    phone?: string;
    status?: EntryStatus;
    company?: string;
    code?: string;
    nationality?: string;
    raceStatus?: string;
    prePackingGroup?: string;
    team?: string;
    eventId?: string;
}

interface State {
    loading: boolean;
    companies: B2bCompany[];
    entries: ListEntry[];
    search: string;
    searchFilter: ISearchFilter;
    deleteConfirmation: Order;
    showDeleteConfirmation: boolean;
    editEntry: ListEntry;
    page: number;
    pageSize: number;
    rowCount: number;
    sortModel: ISortModel<ListEntry>;
}

type editEntryMode = "dialog" | "in-app" | "new-tab";

type IProps = RouteComponentProps & IConfirmContext;
type SearchFilterType = Omit<ListEntriesParameters, "noCount" | "sortProp" | "sortDir" | "begin" | "limit">;

class ManageEntries extends React.Component<IProps, State> {
    static contextType = FirebaseContext;
    state: State;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            editEntry: null,
            loading: true,
            companies: [],
            entries: null,
            deleteConfirmation: null,
            showDeleteConfirmation: false,
            search: "",
            searchFilter: {},
            page: 0,
            pageSize: 10,
            rowCount: 0,
            sortModel: null
        };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        await this.init();
    }

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    }

    editEntry = (entry: ListEntry, mode: editEntryMode): void => {
        switch (mode) {
            case "dialog":
                this.setState({ editEntry: entry });
                break;
            case "in-app":
                this.props.history.push(`manage/${entry.id}`);
                break;
            case "new-tab":
                window.open(`manage/${entry.id}`);
                break;
        }
    };

    render() {
        const { classes } = this.props as any;
        const { companies } = this.state;

        const companyValueGetter = (row: ListEntry) => {
            const company = companies?.find(x => x.id == row.b2bId);
            return company?.name;
        };

        const columnDefinitions: IColumnDefinition<ListEntry>[] = [
            {
                renderCell: (row) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editEntry(row, "dialog") }} size="small">
                            <Edit fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editEntry(row, "new-tab") }} size="small">
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editEntry(row, "in-app") }} size="small">
                            <ArrowForward fontSize="medium" />
                        </IconButton>
                    </>
                }
            },
            { propName: "lastName", label: "Efternamn" },
            { propName: "firstName", label: "Förnamn" },
            { propName: "eventName", label: "Lopp" },
            { propName: "bibtext", label: "Startnummer" },
            { propName: "startgroupName", label: "Startled" },
            { propName: "vasaId", label: "VASA-ID" },
            { propName: "birthDay", label: "Födelsedatum" },
            { propName: "itemPrice", label: "Pris" },
            { propName: "clubName", label: "Klubb" },
            { propName: "email", label: "E-post" },
            { propName: "phone", label: "Telefon" },
            {
                propName: "orderDate",
                label: "Orderdatum",
                valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---"),
                sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---")
            },
            { propName: "status", label: "Status" },
            {
                propName: "b2bId",
                label: "Företag",
                valueFormatter: (row) => companyValueGetter(row),
                sortValueGetter: (row) => companyValueGetter(row)
            },
            { propName: "raceStatus", label: "Racestatus" },
            { propName: "prePackingGroup", label: "Packningsgrupp" },
            { propName: "fisCode", label: "FIS-Kod" },
            { propName: "team", label: "Lag" }
        ];

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return <>
            <SearchFilter<SearchFilterType>
                id={"manage-entries-filter-2"}
                filters={{
                    "lastName": {
                        id: "filter-last-name",
                        type: "DebouncedText",
                        label: "Efternamn",
                        size: 4
                    },
                    "firstName": {
                        id: "filter-first-name",
                        type: "DebouncedText",
                        label: "Förnamn",
                        size: 4
                    },
                    "eventId": {
                        id: "filter-event-period",
                        type: "EventId",
                        label: "Evenemang",
                        size: 4
                    },
                    "bib": {
                        id: "filter-bib",
                        type: "DebouncedText",
                        label: "Startnummer",
                        size: 4
                    },
                    "startgroup": {
                        id: "filter-startgroup",
                        type: "StartGroup",
                        label: "Startled",
                        size: 4
                    },
                    "productVariant": {
                        id: "filter-productVariant",
                        type: "ProductList",
                        label: "Lopp",
                        size: 4,
                        productTypes: ["race", "event", "acred"],
                        useVariants: true,
                        multiple: true
                    },
                    "vasaId": {
                        id: "filter-vasaId",
                        type: "DebouncedText",
                        label: "VASA-ID",
                        size: 4
                    },
                    "birthDate": {
                        id: "filter-birthDate",
                        type: "BirthDate",
                        label: "Födelsedatum",
                        size: 4
                    },
                    "club": {
                        id: "filter-club",
                        type: "DebouncedText",
                        label: "Klubb",
                        size: 4
                    },
                    "email": {
                        id: "filter-email",
                        type: "DebouncedText",
                        label: "E-post",
                        size: 4
                    },
                    "phone": {
                        id: "filter-phone",
                        type: "DebouncedText",
                        label: "Telefon",
                        size: 4
                    },
                    "status": {
                        id: "filter-status",
                        type: "EntryStatus",
                        label: "Status",
                        size: 4
                    },
                    "company": {
                        id: "filter-company",
                        type: "Company",
                        label: "Företag",
                        size: 4,
                        includeArchived: true
                    },
                    "code": {
                        id: "filter-code",
                        type: "DebouncedText",
                        label: "Kod",
                        size: 4
                    },
                    "nationality": {
                        id: "filter-nationality",
                        type: "CountryPicker",
                        label: "Nationalitet",
                        size: 4
                    },
                    "raceStatus": {
                        id: "filter-race-status",
                        type: "RaceStatus",
                        label: "Racestatus",
                        size: 4
                    },
                    "prePackingGroup": {
                        id: "filter-pre-packing-group",
                        type: "DebouncedText",
                        label: "Packningsgrupp",
                        size: 4
                    },
                    "team": {
                        id: "filter-team",
                        type: "DebouncedText",
                        label: "Lag",
                        size: 4
                    },
                }}
                persist={true}
                onInit={async (filter: Filters<SearchFilterType>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<SearchFilterType>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography variant="h5" style={{ display: 'inline' }}>Anmälningar </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </Fragment>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <div style={{ marginTop: 12, marginRight: 12 }}>
                                        <GetAppIcon onClick={this.export} />
                                    </div>
                                </Box>
                            }
                        />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<ListEntry>
                                columnDefinitions={columnDefinitions}
                                data={this.state.entries ?? []}
                                pageSize={this.state.pageSize as PageSize}
                                page={this.state.page}
                                rowCount={this.state.rowCount}
                                paginationMode="server"
                                sortingMode="server"
                                loading={this.state.loading}
                                dense
                                onRequestPageChange={this.setPage}
                                onRequestSort={this.setSortModel}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
            {this.state && this.state.editEntry &&
                <EditEntryDialog
                    entryId={this.state.editEntry.id}
                    onAbortEdit={() => {
                        this.setState({ editEntry: null });
                        this.refresh();
                    }}
                />
            }
        </>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort ordern?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({ loading: true });
    }

    private export = async () => {
        const { searchFilter, sortModel } = this.state;

        const filter = searchFilterToExportExcelFilter<ISearchFilter>(searchFilter);

        if (!filter || filter.length === 0) {
            const result = await this.props.showConfirm("Du håller på att exportera data utan något filter, vill du fortsätta?");
            if (!result) {
                return;
            }
        }

        const request: IExportExcelRequest = {
            entityType: "Entry",
            filter: filter,
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder
        };
        try {
            this.setState({ loading: true });
            await this.api.exportExcel(request);
        } finally {
            this.setState({ loading: false });
        }
    };

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, page: 0, loading: true }, async () => {
            await this.performSearch();
        });
    };

    private setPage = (nextPage: number, pageSize?: number): void => {
        this.setState({ page: nextPage, pageSize: pageSize, loading: true }, () => {
            this.performSearch();
        });
    };

    private setSortModel = (nextSortModel: ISortModel<ListEntry>): void => {
        this.setState({ sortModel: nextSortModel, loading: true }, () => {
            this.performSearch();
        });
    };

    private init = async (): Promise<void> => {
        const companies = await this.api.listB2bCompanies({ includeArchived: true });
        this.setState({ companies: companies });
    };

    private performSearch = debounce(async () => {
        const { page, pageSize, sortModel, searchFilter } = this.state;

        const params: ListEntriesParameters = {
            ...searchFilter,
            sortProp: sortModel?.sortBy as string,
            sortDir: sortModel?.sortOrder,
            begin: page * pageSize,
            limit: pageSize
        };

        const response = await this.api.listEntries(params);
        this.setState({
            entries: response?.entries,
            rowCount: response?.count ?? 0,
            loading: false
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withConfirm(withStyles(useStyles)(ManageEntries)));
